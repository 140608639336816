import React from 'react'
import {Box,VisuallyHidden} from '@chakra-ui/react'
import {motion} from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'


export default function Termine(){
return(
    <motion.div
    initial={{opacity:0,scale:.7,paddingBottom:"100px"}}
    animate={{opacity:1,scale:1}}
    transition={{duration:1,delay:.3}}
    >

<Box  maxWidth="900" height="90%" margin="auto" textAlign="center">
    <VisuallyHidden>Termine für den Ausstellungsparcours</VisuallyHidden>
    <StaticImage src="../images/solo/programm-verluste.jpg" alt="Termin-Plakat" />
  </Box>
</motion.div>
)


}